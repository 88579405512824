<template>
  <div class="goods">
    <van-cell-group>
      <van-search v-model="value" placeholder="请输入搜索关键词" @search="onSearch" />
    </van-cell-group>
    <van-cell-group>
      <van-cell :title="query.name" style="font-weight: bold;"/>
      <van-collapse v-model="activeIndex" >
        <van-collapse-item v-for="(item,index) in items" :key="index" :name="index">
          <template #title>{{item.name}}</template>
          <template>
            <div class="van-tab__text-wrapper">{{item.content}}</div>
          </template>
        </van-collapse-item>
      </van-collapse>
      <van-cell v-if="visible">
        <van-empty description="很抱歉，没有数据"/>
      </van-cell>
    </van-cell-group>

    <van-row style="padding-top: 20px;">
      <van-col span="4"></van-col>
      <van-col span="3">
        <van-button type="danger" class="button1" onclick="openIndex()">返回上层</van-button>
      </van-col>
      <van-col span="6"></van-col>
      <van-col span="3">
        <van-button type="primary" class="button2" onclick="javascript:window.location.href='xiangyuan://kefu';">联系客服</van-button>
      </van-col>
      <van-col span="4"></van-col>
    </van-row>
  </div>
</template>

<script>
import {queryAnswerList} from "@/api/interface";
import { Tag,  Grid,  GridItem,  Col,  Swipe,  SwipeItem,  Cell,  CellGroup,  Icon,  Collapse,
  CollapseItem,  Button,  Image,  Row,  Search,  Toast,  NoticeBar, Empty} from 'vant';

export default {
  name: "List",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Row.name]: Row,
    [Search.name]: Search,
    [Toast.name]: Toast,
    [Empty.name]: Empty,
  },
  data() {
    return {
      query:{
        place: 0,
        name: '',
        text: ''
      },
      value: '',
      visible: false,
      activeIndex : ["0"],
      items: [],
    }
  },
  created() {
    this.query.place = this.$route.query.place
    this.query.name = this.$route.query.name
    this.query.text = this.$route.query.text
    this.value = this.$route.query.text
    this.queryAnswerList();

    window.openIndex = this.openIndex
  },
  methods: {
    queryAnswerList() {
      let _this = this;
      const params = {
        place: this.query.place,
        text: this.query.text,
      }
      queryAnswerList(params).then(res => {
        const array = new Array()
        res.data.data.forEach(function (e) {
          let json = {
            id: e.id,
            name: e.name,
            content: e.content
          };
          array.push(json)
        })
        _this.items = array
        if (array.length == 0){
          _this.visible = true
        }
      })
    },
    onSearch(val) {
      this.query.text = val
      this.$route.query.text = val
      this.queryAnswerList();
    },
    openIndex(){
      this.$router.push({path:'/',query: {text:this.query.text}})
    }
  }
}
</script>

<style lang="less">
.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }

  .button1 {
    width:100px;
    height:35px;
    border-radius:5px;
  }

  .button2 {
    width:100px;
    height:35px;
    border-radius:5px;
  }

  .van-tab__text-wrapper{
    white-space: pre-wrap;
  }

}
</style>
